import Cards from "./pages/Cards";
import './App.css';

function App() {
  return (
    <Cards />
  );
}

export default App;
